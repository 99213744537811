{
  "static/audio/click.wav": "static/audio/click.71097bab5de4e6d628b4aca6e752a63f.wav",
  "static/audio/death.wav": "static/audio/death.300d019b72205bb690f9cf1b77d4bdf8.wav",
  "static/audio/explosion.wav": "static/audio/explosion.414cc72d3e3414a15f835d7075cfc4ab.wav",
  "static/audio/plant.wav": "static/audio/plant.813fecdd078467dd90fc6b73b52b2877.wav",
  "static/audio/reveal.wav": "static/audio/reveal.bf7d1eded42c47100a123a11b1c8776d.wav",
  "static/file/humans.txt": "static/file/humans.098009031e76982ae2b6cdbb015bc9ab.txt",
  "static/file/robots.txt": "static/file/robots.5e0bd1c281a62a380d7a948085bfe2d1.txt",
  "static/file/sitemap.xml": "static/file/sitemap.f1d5eae1884f82b7a4fac2dd2522126d.xml",
  "static/image/.DS_Store": "static/image/.ab772f47a66d717f23c536ab1e456991.DS_Store",
  "static/image/assets/eight.png": "static/image/assets/eight.1a71331cd48ead489dc9f6cb430c4667.png",
  "static/image/assets/five.png": "static/image/assets/five.a5dd0c37ba5df863a6e37b11bb0f6ac2.png",
  "static/image/assets/flag.png": "static/image/assets/flag.0b39daf2940c70886d0165f04558ecdd.png",
  "static/image/assets/four.png": "static/image/assets/four.caf71827736160c35a7fb9f3415c8bd7.png",
  "static/image/assets/hidden.png": "static/image/assets/hidden.6237251a7634dfe0cd09887705303515.png",
  "static/image/assets/mine.png": "static/image/assets/mine.3cc12416235dcab36ec069e8f072ab4a.png",
  "static/image/assets/one.png": "static/image/assets/one.c306ce5bfd1f6859737002830a231efe.png",
  "static/image/assets/seven.png": "static/image/assets/seven.034de81b0be959209418d28d9495172b.png",
  "static/image/assets/six.png": "static/image/assets/six.b3ac53ba18afaa949695fd4d5b6b4ac5.png",
  "static/image/assets/three.png": "static/image/assets/three.49c77b6cca5fee2ad7e97a90696e0488.png",
  "static/image/assets/tilesheet.png": "static/image/assets/tilesheet.7b1db08a20422b4b2501b7d02fe94995.png",
  "static/image/assets/two.png": "static/image/assets/two.849c22f2d32ad9cb3edc1fc849deec22.png",
  "static/image/assets/zero.png": "static/image/assets/zero.0318a2dbd67f26479ee48b2c33893604.png",
  "static/image/favicon/favicon.ico": "static/image/favicon/favicon.f252a3818b84d8a203205c651a7994b8.ico",
  "static/image/page/404/stay-on-marked-trail.webp": "static/image/page/404/stay-on-marked-trail.f0c1da590d3184b2a0eb30688d1cd658.webp",
  "static/image/social/.DS_Store": "static/image/social/.194577a7e20bdcc7afbb718f502c134c.DS_Store",
  "static/image/social/one-v-one.webp": "static/image/social/one-v-one.da07874e8746d301d8bf7e560f915632.webp",
  "static/script/main.js": "static/script/main.2ec8a78fd1e9bda42f91a64ed8326118.js",
  "static/script/main.js.map": "static/script/main.59e7735d70a7d9f352ac40633634dbdf.js.map",
  "static/script/minesweeper-royale.js": "static/script/minesweeper-royale.5f6e51b9e67501ee5b2704d54b53ab0a.js",
  "static/script/minesweeper-royale.js.map": "static/script/minesweeper-royale.9404fbcc8409f56fff03f2ab0f5d4cc4.js.map",
  "static/stylesheet/main.css": "static/stylesheet/main.aaf4525eb57680f63e6af7a5ad227fae.css"
}